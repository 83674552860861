@charset "UTF-8";
/* CSS Document */


html {
    font-size: 16px;
}

body {
    font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: #231f20;
    font-size: 16px;
}

.def {
    font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.tooltip-inner {
    text-align: left;
}

.toolbar {
    margin-top: -20px;
    background-color:#f9fbfc;
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f4f5f7", endColorstr="#fff");
    background-image: -moz-linear-gradient(top, #f4f5f7, #fff);
    background-image: -ms-linear-gradient(top, #f4f5f7, #fff);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f4f5f7), color-stop(100%, #fff));
    background-image: -webkit-linear-gradient(top, #f4f5f7, #fff);
    background-image: -o-linear-gradient(top, #f4f5f7, #fff);
    background-image: linear-gradient(#f4f5f7, #fff);
    border-color: transparent;
    color: #fff !important;
}


.open .btn-box-tool, .btn-box-tool:hover {
    color: #00adef;
}

a.white {
    color: #fff;
}

a.white:hover {
    color: #fff;
    text-decoration: underline;
}


.bold{
    font-weight: bold;
}

.center {
    margin: 0 auto;
}

.filebox {
    font-size: 1.25rem;
    background-color: #8dc3e3;
    position: relative;
    padding: 60px 20px;
}

.container {
    margin-left: auto;
    margin-right: auto;
    padding-top: 15px;
    padding-left: 0px;
    padding-right: 0px;
}

h1, .h1 {
    font-size: 2em;
    font-weight: normal;
    margin-bottom: 10px;
    margin-top: 1px;
}

h2, .h2 {
    font-size: 1.75rem;
    font-weight: normal;
    margin-top: 10px;
    margin-bottom: 20px;
}


h3, .h3 {
    font-size: 1.65rem;
    color: #446f78;
    font-weight: normal;
    font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;
}


h4, .h4 {
    font-size: 1.2rem;
}

h5, .h5 {
    font-size: 1.15rem;
}

.clickbox {
    background-color: #6fbdea;
    padding-top: 5px;
    padding-bottom: 25px;
}

.drop {
    color: #fff;
    font-size: 1.45em;
    text-align: center;
    outline: 2px dashed #fff;
    background-color: #6fbdea;
    width: 90%;
    margin: 0 auto;
    padding: 30px;
}

.dropzone, .dropzone * { box-sizing: border-box; }


.dropzone {
    min-height: 150px;
    padding-top: 20px;
    margin-top: 20px;
    background: #6fbdea;
    border: 0;
}

div.helpfileupload {
    min-height: 10px;
    padding: 0px;
    margin: 0px;
    background: #6fbdea;
    border: 0;
}

div.helpfileupload .dz-message {
	padding: 0px;
	margin: 0px;
}

.box .overlay > .fa, .overlay-wrapper .overlay > .fa {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -15px;
    margin-top: 0px;
    color: #ff4431;
    font-size: 1em;
}

/* fonts */

.this {
    color: #231f20;
    padding-top: 2px;
    padding-bottom: 2px;
}

.this:hover {
    background-color: #0078a6;
    color: #fff;
}

.f7 {
    font-size: .7em;
}

.f8 {
    font-size: .8em;
}

.f9 {
    font-size: .9em;
}


.f12 {
    font-size: 1.2em;
}

.f15 {
    font-size: 1.5em;
}
.breadcrumb {
    padding-top: none;
    padding-bottom: none;
    margin-bottom: 0;
    list-style: none;
    background-color: #fff;
    border-radius: 0.25rem;
}

.breadcrumb-item.active {
    color: #0078a6;
    margin-top: 1px;
}

.breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.8rem;
    padding-left: 0.8rem;
    color: #818a91;
    font-family: FontAwesome;
    font-size: .9em;
    margin-top: 4px;
    content: "\f0da";
}

.unshared {
    padding-top: 10px;
    padding-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;
    min-height: 50px;
    vertical-align: top;
    margin-bottom: 10px;
    margin-top: 10px;
    color: #231f20;
    font-size: 1em;
}
.shared {
    background-color: #cce7f9;
    border: 1px solid #cce7f9;
    padding-top: 10px;
    padding-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;
    min-height: 50px;
    vertical-align: top;
    width: 235px;
    margin-bottom: 10px;
    margin-top: 10px;
    color: #231f20;
    font-size: 1em;
}

.shared:hover {
    background-color: #fff;
    border: 1px solid #546e7a;
    padding-top: 10px;
    padding-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;
    min-height: 50px;
    width: 235px;
    margin-bottom: 10px;
    margin-top: 10px;
    color: #546e7a;
    font-size: 1em;
}


.arrow_box {
	position: relative;
	background: #0078a6;
    height: 50px;
    width: 235px;
    padding-top: 10px;
    padding-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;
    color: #fff;
    font-size: 1em;
}
.arrow_box:after, .arrow_box:before {
	left: 100%;
	top: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.arrow_box:after {
	border-color: rgba(2, 162, 192, 0);
	border-left-color: #0078a6;
	border-width: 25px;
	margin-top: -25px;
}
.arrow_box:before {
	border-color: rgba(194, 225, 245, 0);
	border-left-color: #0078a6;
	border-width: 25px;
	margin-top: -25px;
}

.arrow_boxNew {
	position: relative;
	background: #0078a6;
    height: 57px;
    width: 235px;
    padding-top: 10px;
    padding-bottom: 18px;
    padding-left: 10px;
    padding-right: 10px;
    color: #fff;
    font-size: 1em;
}
.arrow_boxNew:after {
	left: 100%;
	top: 50%;
	border: solid;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(2, 162, 192, 0);
	border-left-color: #0078a6;
	border-width: 29px;
	margin-top: -29px;
}


.dataset {
        background-color:#00adef;
        margin-top: -20px;
        margin-left: -20px;
        margin-right: -20px;
        padding-top: 15px;
        padding-bottom: 5px;
        color: #fff;
        }

.panel-heading [data-toggle="collapse"]:after {
    font-family: 'FontAwesome';
    content: "\f078";
    float: left;
    width: 20px;
    color: #00adef;
    font-size: 12px;
    line-height: 10px;
}

.panel-heading [data-toggle="collapse"].collapsed:after {
        /* opened panel */
        content: "\f054";
		width: 20px;
		color: #00adef;
    }
.smallish {
  font-size: .5em;
}

.content-wrapper {
    margin-left: 0;
}


/* cards */
.box {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    background-color: #fff;
    border-top: 3px solid #00adef;
    border-left: 1px solid #ECEFF1;
	border-right: 1px solid #ECEFF1;
	border-radius: 3px;
	background-color: #ffffff;
    box-shadow: 0 1px 1px rgba(0,0,0,.05)
}

.box.box-primary {
  border-top-color: #00adef;
}

.box_notop {
    border-top: 1px solid #e2e9f3;
}

.box.box-gray {
  border-top-color: #869aa0;
    border-top: 1px solid #869aa0;
}



.box-header > .fa,
.box-header > .glyphicon,
.box-header > .ion,
.box-header .box-title {
    display: inline-block;
    font-size: 1.5em;
    color: #0078a6;
    margin: 0 auto;
    line-height: 1.9;
}

.card {
    text-align: center;
    box-shadow: 0 1px 2px rgba(0,0,0,.12)
}

.box-title {
    margin-bottom: 0;
    text-align: center;
    font-size: 1.5em;
    font-weight: normal;
    text-align: center;
}

.card_icon {
    margin: 0 auto;
    width: 70px;
    height: 70px;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 20px;
    font-size: 30px;
    line-height: 1.33;
    border-radius: 36px;
    background-color: #00adef;
    color: #fff;
}

.card_icon_stacked {
    margin-left: -13px;
    font-size: 18px;
    margin-top: -10px;
}

.card_icon_stacked_sm {
    margin-left: -10px;
    font-size: 14px;
}


.card_icon2 {
    width: 50px;
    height: 50px;
    padding-top: 8px;
    padding-left: 12px;
    font-size: 28px;
    line-height: 1.33;
    border-radius: 50%;
    background-color: #00adef;
    color:#fff;
}

.card_icon2_stacked {
    margin-left: -13px;
    font-size: 18px;
    margin-top: -10px;
}

.card_icon_stacked_sm2 {
    margin-left: -10px;
    font-size: 14px;
    color: #00adef;
}

/* success modal */
.card_icon_success {
    margin: 0 auto;
    text-align: center;
    margin-top: -35px;
    width: 60px;
    height: 60px;
    padding-top: 10px;
    font-size: 30px;
    line-height: 1.33;
    border-radius: 50%;
    background-color: #0ac746;
    color:#fff;
}

/* danger modal  */
.card_icon_danger {
    margin: 0 auto;
    text-align: center;
    margin-top: -35px;
    width: 60px;
    height: 60px;
    padding-top: 10px;
    font-size: 30px;
    line-height: 1.33;
    border-radius: 50%;
    background-color: #ff4431;
    color:#fff;
}

/* question modal  */
.card_icon_question {
    margin: 0 auto;
    text-align: center;
    margin-top: -35px;
    width: 60px;
    height: 60px;
    padding-top: 10px;
    font-size: 30px;
    line-height: 1.33;
    border-radius: 50%;
    background-color: #00BCD4;
    color:#fff;
}


.modal-header {
  border-bottom-color: #f4f4f4;
  background-color: #0078a6;
  border-top-left-radius: 3px;
  border-top-right-radius:3px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  color: #fff;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
}

.modal-header3 {
    padding-top: 10px;
    padding-bottom: 5px;
    border-bottom: none;
}

.modal-title3 {
    padding-top: 15px;
    font-size: 2em;
    text-align: center;
    margin-top: 10px;
}


.modal-backdrop.fade.in {
    opacity: 0;
}

.modal-content {
  position: relative;
  background-color: #fff;
  -webkit-background-clip: padding-box;
          background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.01);
  border-radius: 0.3rem;
  outline: 0;
}

/* .modal-transparent */

.modal-transparent {
  background: none;
}
.modal-transparent .modal-content {
  background: transparent;
}
.modal-backdrop.modal-backdrop-transparent {
  background: #ffffff;
}
.modal-backdrop.modal-backdrop-transparent.in {
  opacity: 1;
  filter: alpha(opacity=1);
}

 .message.success .message-icon > i {
    color: #01BD2E;
}

.message.confirm .message-icon > i {
    color: #00adef;
}
.message .message-icon > i {
    font-size: 22px;
    line-height: 50px;
    width: 50px;
    height: 50px;
    color: #000;
    border-radius: 100%;
    background-color: #fff;
}
.message-icon > i {
    color: #000;
    font-size: 22px;
    background: #fff;
    height: 50px;
    line-height: 50px;
    width: 50px;
    border-radius: 100%;
}
.message .message-icon {
    display: table-cell;
    width: 60px;
    padding: 10px 10px 0 20px;
    text-align: center;
    vertical-align: top;
}

.message.success {
    background-color: #0ac746;
    border-top: 1px solid #01BD2E;
    border-left: 1px solid #01BD2E;
    border-right: 1px solid #01BD2E;
    border-bottom: 8px solid #01BD2E;
}

.message.confirm {
    background-color: #00BCD4;
    border-top: 1px solid #00BCD4;
    border-left: 1px solid #00BCD4;
    border-right: 1px solid #00BCD4;
    border-bottom: 8px solid #00ACC1;
}

.message.confirm .message-icon > i {
    color: #00BCD4;
}

.message.failure {
    background-color: #ed1c24;
    border-top: 1px solid #ed1c24;
    border-left: 1px solid #ed1c24;
    border-right: 1px solid #ed1c24;
    border-bottom: 8px solid #c6222f;
}
.message.failure .message-icon > i {
    color: #ed1c24;
}

.message.warning {
    background-color: #FFA301;
    border-top: 1px solid #FFA301;
    border-left: 1px solid #FFA301;
    border-right: 1px solid #FFA301;
    border-bottom: 8px solid #e5970d;
}

.message.warning .message-icon > i {
    color: #FFA301;
}

.message {
    position: relative;
    display: block;
    width: 350px;
    margin: 90px auto 0;
    transition: all .2s ease;
    color: #fff;
    border-radius: 4px;
}
 .message .message-body {
    display: table-cell;
    padding: 20px 20px 10px 10px;
    vertical-align: middle;
     font-size: 1em;
}


i:hover {
    color: #00adef;
}

i.active {
    color:#00adef !important;
}

i.no:hover {
    color: #fff;
}

i.noblack:hover {
    color: #231f20;
}

i.nowhite:hover {
    color: red;
}

i.noyellow:hover {
    color: #FFA301;
}

.link-icon {
    font-weight: normal;
    margin-top: 2px;
    font-size: 1.1em;
    text-align: right;
    color: #00adef;
}
.link-icon:hover {
    font-weight: bold;
    margin-top: 2px;
    font-size: 1.1em;
    text-align: right;
}

.configure {
    border-left: 1px solid #dce9ec;
    padding-left: 25px;
}


/*  gradients and colors  */

.formgray {
    color: #607D8B !important;
}

.op5 {
    opacity: .5;
}
.blue {
    color: #00adef !important;
}


.bg_blue {
    background-color: #00adef !important;
}

.bg_dropblue {
    background-color: #8dc3e3;
}


.bg_lightblue {
    background-color: #cce7f9;
}
.darkblue {
    color: #0078a6;
}

.darkblue_gr {
    font-size: 1.2em;
    color: #0078a6;
}

.bg_darkblue {
    background-color: #0078a6 !important;
}

.border_darkblue {
    color: #0078a6 !important;
    background-color: #fff !important;
    border: 2px solid #0078a6;
}

.border_black {
    color: #231f20 !important;
    background-color: #fff !important;
    border: 2px solid #231f20;
    vertical-align: middle;
}

.border_yellow {
    color: #231f20 !important;
    background-color: #FFA301 !important;
    border: 2px solid #FFA301;
}

.border_green {
    color: #28B837 !important;
    background-color: #fff !important;
    border: 2px solid #28B837;
}

.yellow {
    /* color: #ffdd17
    color: #FF9700  */
    color:#FFA301
}

.bg_yellow {
    background-color: #ffdd17 !important;
}

.orange {
    color: #FF2C00
}

.bg_orange {
    background-color: #FF2C00 !important;
}

.bg-yellow, .callout.callout-warning, .alert-warning, .label-warning, .modal-warning .modal-body {
    background-color: #FFA301 !important;
}

.gray {
   color: #78909C
}

.bg_gray
{
   background-color: #78909C !important;
}


.light_gray {
    color: #f9fbfc;
}

.bg_light_gray {
    background-color: #f9fbfc !important;
}

.medium_gray {
    color: #b8cad5
}

.bg_medium_gray {
    background-color: #b8cad5 !important
}

.dark_gray {
    color: #69838c !important;
}

.bg_dark_gray {
    background-color: #69838c !important;
}

.green {
   /* color: #0ac746; */
    color: #28B837
}

.bg_green {
   background-color: #0ac746 !important;
}

.darkgreen {
    color: #006738
}

.bg_darkgreen {
    background-color: #006738 !important;
}

.alert-success {
    background-color: #28B837;
    border-color: #d0e9c6;
    color: #fff;
    text-align: center;
    font-size: .9em;
}

.alert-warning {
    background-color: #FFA301;
    border-color: #FFA301;
    color: #fff;
    text-align: center;
    font-size: .9em;
}

.alert-danger {
    background-color: #ED1C24;
    border-color: #ED1C24;
    color: #fff;
    text-align: center;
    font-size: .9em;
}

.black {
    color: #231f20
}

.bgblack {
    background-color: #231f20 !important;
}

.purple {
    color: #8D95EC;
}

.bgpurple {
    background-color: #8D95EC !important;
}

.red {
    color: #ED1C24;
}

.bgred {
    background-color: #ED1C24;
}


.white {
    color: #fff !important;
}


.bgwhite {
    background-color: #fff !important;
}



/*  borders  */

.border-right {
    border-right: solid 1px #d3d3d3;
}

.border-left {
    border-left: solid 1px #d3d3d3;
}

.no-border-radius {
  border-radius: 0px !important;
}

.no-border {
    border: 0;
}

a {
    color: #0078a6;
}

a:hover{
    color: #00adef;
}

a.white:hover{
    color: #000;
}


a.side:hover{
    color: #dce9ec;
}

a:active,
a:focus {
  outline: none;
  text-decoration: none;
  color: #0078a6;
}

.height400 {
    height: 400px;
}

.sharebox {
    color: #ff8904;
    border: 1px solid #ff8904;
    padding: 5px;
}

.why {
    background-color: #f9fbfc;
    padding: 10px;
    line-height: 2;
    height: 450px;
}

.rtime {
    display: inline-block;
    margin-right: 8px;
}

/*  margins and padding  */
.martop0 {margin-top: 0px;}
.martop5 {margin-top: 5px;}
.martop6 {margin-top: 6px;}
.martop8 {margin-top: 8px;}
.martopneg5 {margin-top: -5px}
.martop10 {margin-top: 10px;}
.martop15 {margin-top: 15px}
.martop20 {margin-top: 20px}
.martop30 {margin-top: 30px}
.martop80 {margin-top: 80px}
.martopneg10 {margin-top: -10px}
.martopneg20 {margin-top: -20px}
.martop100 {margin-top: 100px}
.martop150 {margin-top: 150px}

.marbot5 {margin-bottom: 5px}
.marbot10 {margin-bottom: 10px}
.marbot20 {margin-bottom: 20px}

.marleft2 {margin-left: 2px;}
.marleft5 {margin-left: 5px;}
.marleft10 {margin-left: 10px;}
.marleft14 {margin-left: 14px;}
.marleft15 {margin-left: 15px;}
.marleft20 {margin-left: 20px;}

.marleftneg25 {margin-left: -25px;}
.marleftneg50 {margin-left: -50px;}

.marright2 {margin-right: 2px;}
.marright5 {margin-right: 5px;}
.marright10 {margin-right: 10px;}
.marright15 {margin-right: 15px;}
.marright20 {margin-right: 20px;}
.marright80 {margin-right: 80px;}

.marLR10 {
    margin-left: 10px;
    margin-right: 10px;
}

.marTB {
    margin-top: 19px;
    margin-bottom: 5px;
}

.pad0 {
    padding-left: 0;
    padding-right: 0;
}

.padleft0 {
    padding-left: 0px;
}

.padleft2 {
    padding-left: 2px;
}

.padleft5 {
    padding-left: 5px;
}

.padleft9 {
    padding-left: 9px;
}

.padleft20 {
    padding-left: 20px;
}

.padleft25 {
    padding-left: 25px;
}

.padright0 {
    padding-right: 0px}

.padright5 {
    padding-right: 5px}

.padright25 {
    padding-right: 25px}

.padtop0 {
    padding-top: 0px;
}

.padtop5 {
    padding-top: 5px;
}

.padtop10 {
    padding-top: 10px;
}

.padtop12 {
    padding-top: 12px;
}

.padbot5 {
    padding-bottom: 5px;
}

.padall2 {
    padding: 2px;
}

.padall5 {
    padding: 5px;
}

.padall0 {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
}
.padall20 {
    padding: 20px;
}

.padLR20 {
    padding-left: 20px;
    padding-right: 20px;
}

.padTB2 {
    padding-top: 2px;
    padding-bottom: 2px;
    margin-right: 8px;
}

/* forms */

option {
    color: #55595c;
}

option:hover{
    background-color: #0078a6;
    color: #fff;
}

.custom-select2  {
    display: inline-block;
    background-color: #fff;
    max-width: 100%;
    font-size: .9em;
    color: #231f20;
    padding: 0.25rem 1.75rem 0.25rem 0.75rem;
    margin-bottom: 3px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    -moz-appearance: none;
    -webkit-appearance: none;
}


label, .label {
    color: #231f20;

}

.form-control {
    display: block;
   color: #546e7a;
    font-size: 16px;
    width: 100%;
    padding: 3px 5px;
    min-height: 34px;
    line-height: 1.42857143;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ECEFF1;
    border-radius: 4px;
    box-shadow: none;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    font-size: 16px;
}


.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #ffffff;
  opacity: 1;
   border: none
}


.form-control2[disabled],
.form-control2[readonly],
fieldset[disabled] .form-control {
  background-color: transparent;
  opacity: 1;
   border: none
}

.form-control:focus  {
    border-color:#0078a6 !important;
    box-shadow: none !important;
    outline: none;
}


.form-control2 {
     padding: 0.2rem 0.5rem;
    font-size: .9em;
     color: #231f20;
}

.form-control3 {
    padding: 2px;
    line-height: 1;
    font-size: .9em;
    border: 1px solid #ECEFF1;
    border-radius: 4px;
}

.form-control4 {
    padding: 2px;
    line-height: 1;
    font-size: .9em;
    border: 1px solid #ff4431;
    border-radius: 4px;
}

.form-control3:focus  {
    border-color: #0078a6 !important;
    box-shadow: none !important;
    outline: none;
}

.form-control4:focus  {
    border-color:#0078a6 !important;
    box-shadow: none !important;
    outline: none;
}
/*
.form-control3 {
     display: block;
   color: #546e7a;
    width: 100%;
    padding: 1px 2px;
    min-height: 24px;
    font-size: .9em;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ff4431;
    border-radius: 4px;
    box-shadow: none;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}
*/

.col-form-label {
    padding-top: 0.3rem;
    padding-bottom: 0.5rem;
    margin-bottom: 0;
    vertical-align: middle;
}

.form-check-label {
    padding-right: 15px;
}

.form-check-input {
    margin-right: 5px;
}

.searchBox {
    margin-left: -4px;
    margin-top: -10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    line-height:  1.4;
    height: 34px;
}

.input-group-addon {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.25;
    text-align: center;
    width: 50px;
    background-color: #eceeef;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
}

/*  widths  */
.w1p {width: 1%;}
.w10 {width: 10px}
.w30 {width: 30px;}
.w30p {width: 30%;}
.w50 {width: 50px !important;}
.w50p {width: 50%;}
.w60 {width: 60px}
.w100 {width: 100px}
.w100p {width: 100%}
.w120 {width: 120px}
.w140 {width: 140px}
.w300 {width: 300px}
.w500 {width: 500px}
.w70p {width: 70%}

.mw200 {
    min-width: 200px;
}
.login-right {
    padding-top: 20px;
    padding-left: 20px;
}

.grid_icons {
    width: 200px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: red;
}

/* picklist */
.v-center {
  min-height:200px;
  display: flex;
  justify-content:center;
  flex-flow: column wrap;
}



.item2:not(:last-child) {
  margin-right: -2px;
}


.list-group-item.active, .list-group-item.active:focus, .list-group-item.active:hover {
    z-index: 2;
    color: #fff;
    text-decoration: none;
    background-color: #0078a6;
    border-color: #0078a6;
    font-size: 1.1em;
        border-top-right-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
}


img.bg {
    /* Set rules to fill background */
    min-height: 100%;
    min-width: 1024px;

    /* Set up proportionate scaling */
    width: 100%;
    height: auto;

    /* Set up positioning */
    position: fixed;
    top: 0;
    left: 0;
}

@media screen and (max-width: 1024px){
    img.bg {
        left: 50%;
        margin-left: -512px;
    }
}

.page-wrap {
    position: relative;
    width: 60%;
    margin-top: 50px;
    padding-left: 20px;
    font-size: 1em;
}

.login-header {
    font-size: 1.6em;
    padding-bottom: 20px;
}

 /*   buttons   */


.btn-tiny {
    padding: 0rem 0.2rem 0rem 0.2rem ;
    margin-top: 3px;
    font-size: .9rem;
    border: 1px solid #00adef;
    color: #00adef;
    border-radius: 3px;
}

.btn-tiny_red {
    margin-top: 3px;
    margin-bottom: 3px;
    padding: 0rem 0.2rem 0rem 0.2rem ;
    font-size: .9rem;
    border: 1px solid #ff4431;
    border-radius: 3px; color: #fff;
    background-color: #ff4431;
}

.btn-gray {
    background-color: #455a64;
    background-image: none;
    color: #fff !important;
    border-radius: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 16px;
}

.btn-gray:hover, .btn-gray:active, .btn-gray.hover {
    background-color: #00adef;
    background-image: none;
    color: #fff !important;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 16px;
}

.btn-red {
    background-color: #ff4431;
    background-image: none;
    color: #fff !important;
    border-radius: 0;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 16px;
}


.btn-red:hover, .btn-red:active, .btn-red.hover {
    background-color: #00adef;
    background-image: none;
    color: #fff !important;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 16px;
}

.btn-blue {
    background-color: #00BCD4;
    background-image: none;
    color: #fff !important;
    border-radius: 0;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 16px;
}


.btn-blue:hover, .btn-blue:active, .btn-blue.hover {
    background-color: #00adef;
    background-image: none;
    color: #fff !important;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 16px;
}

.btn-green {
    background-color: #0ac746;
    background-image: none;
      color: #fff !important;
    width: 100%;
    border-radius: 0px 0px 3px 3px; /* top left, top right, bottom right, bottom left */
    border-top: 1px solid #fff;
    border-bottom: 1px solid #0ac746;
     padding-top: 15px;
    padding-bottom: 15px;
}


.btn-green:hover, .btn-green:active, .btn-green.hover {
    background-color: #00adef;
    background-image: none;
    color: #fff !important;
    width: 100%;
    border-radius: 0px 0px 3px 3px; /* top left, top right, bottom right, bottom left */
    border-top: 1px solid #fff;
    border-bottom: 1px solid #00adef;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 16px;
}

.btn-group-xs>.btn, .btn-xs {
    padding: 2px 5px;
    font-size: .8em;
    line-height: 1.5;
    border-radius: 3px;
}

.btn-info {
    font-size: 16px;
}

.btn-danger {
    background-color: #ED1C24;
    border-color: #C6222F;
    font-size: 16px;
}

.btn-danger:active {
    background-color: #ED1C24 !important;
    color: #fff !important;
    border: 1px solid #ED1C24;
    background-image: none;
    font-size: 16px;
}

.btn-danger:hover, .btn-danger:active, .btn-danger.hover {
    background-color: #00adef !important;
    color: #fff !important;
    border: 1px solid #00adef;
    background-image: none;
    font-size: 16px;
}


.btn-primary {
    background-color: #00adef;
background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00adef", endColorstr="#0078a6");
  background-image: -khtml-gradient(linear, left top, left bottom, from(#00adef), to(#0078a6));
  background-image: -moz-linear-gradient(top, #00adef, #0078a6);
  background-image: -ms-linear-gradient(top, #00adef, #0078a6);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #00adef), color-stop(100%, #0078a6));
  background-image: -webkit-linear-gradient(top, #00adef, #0078a6);
  background-image: -o-linear-gradient(top, #00adef, #0078a6);
  background-image: linear-gradient(#00adef, #0078a6);
  border-color: #00adef;
  color: #fff !important;
  -webkit-font-smoothing: antialiased;
    font-size: 16px;
}


.btn-primary:hover, .btn-primary:active, .btn-primary.hover {
    background-color: #00adef !important;
    color: #fff !important;
    border: 1px solid #00adef;
    background-image: none;
    font-size: 16px;
}

.btn-primary:disabled,
.btn-primary[disabled] {
  background-color: #e0e0e0;
  background-image: none;
        font-size: 16px;
}


.btn-primary.disabled, .btn-primary[disabled], fieldset[disabled] .btn-primary, .btn-primary.disabled:hover, .btn-primary[disabled]:hover, fieldset[disabled] .btn-primary:hover, .btn-primary.disabled:focus, .btn-primary[disabled]:focus, fieldset[disabled] .btn-primary:focus, .btn-primary.disabled.focus, .btn-primary.focus[disabled], fieldset[disabled] .btn-primary.focus, .btn-primary.disabled:active, .btn-primary[disabled]:active, fieldset[disabled] .btn-primary:active, .btn-primary.disabled.active, .btn-primary.active[disabled], fieldset[disabled] .btn-primary.active {
    background-color: #ccc;
    border-color: #CCC;
	color: #000;
        font-size: 16px;
}


.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0078a6;
  border-color: #0078a6;
        font-size: 16px;
}

.btn-primary:active, .btn-primary.active,
.open > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0078a6;
  border-color: #0078a6;
  background-image: none;
        font-size: 16px;
}

.btn-primary:active:hover, .btn-primary:active:focus, .btn-primary:active.focus, .btn-primary.active:hover, .btn-primary.active:focus, .btn-primary.active.focus,
.open > .btn-primary.dropdown-toggle:hover,
.open > .btn-primary.dropdown-toggle:focus,
.open > .btn-primary.dropdown-toggle.focus {
  color: #fff;
  background-color: #0078a6;
  border-color: #0078a6;
        font-size: 16px;
}

.btn-med, .btn-group-med > .btn {
    padding: 0.25rem 0.5rem;
    font-size: .8 rem;
    border-radius: 0.2rem;
}

.invite {
    text-decoration: underline;
}

.invitetop {
    background-color: #0078a6;
    text-align: center;
    color: #fff;
    border-radius: 3px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top: -10px;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 5px;
    font-size: 1.05em;
}

.btn-defaultdrop {
    background-color: #e0e0e0;
  border-color: #dbdbdb;
    background-image: none;
    border-radius: 3px;
  color: #325158 !important;
  -webkit-font-smoothing: antialiased;
}


.btn-default {
    background-color: #dcdde0;
background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#dcdde0");
  background-image: -khtml-gradient(linear, left top, left bottom, from(#ffffff), to(#dcdde0));
  background-image: -moz-linear-gradient(top, #ffffff, #dcdde0);
  background-image: -ms-linear-gradient(top, #ffffff, #dcdde0);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffffff), color-stop(100%, #dcdde0));
  background-image: -webkit-linear-gradient(top, #ffffff, #dcdde0);
  background-image: -o-linear-gradient(top, #ffffff, #dcdde0);
  background-image: linear-gradient(#ffffff, #dcdde0);
  border-color: #dcdde0;
  color: #325158 !important;
  -webkit-font-smoothing: antialiased;
        font-size: 16px;
}


.btn-default:hover, .btn-default:active, .btn-default.hover {
    background-color: #00adef !important;
    color: #fff !important;
    border: 1px solid #00adef;
    background-image: none;
        font-size: 16px;
}

.btn-default2:hover, .btn-default:active, .btn-default.hover {
    background-color: #0078a6 !important;
    color: #fff !important;
    border: 1px solid #0078a6;
    background-image: none;
        font-size: 16px;
}

.btn-default:disabled,
.btn-default[disabled] {
  background-color: #e0e0e0;
  background-image: none;
        font-size: 16px;
}


.btn-default.disabled, .btn-default[disabled], fieldset[disabled] .btn-default, .btn-default.disabled:hover, .btn-default[disabled]:hover, fieldset[disabled] .btn-default:hover, .btn-default.disabled:focus, .btn-default[disabled]:focus, fieldset[disabled] .btn-default:focus, .btn-default.disabled.focus, .btn-default.focus[disabled], fieldset[disabled] .btn-default.focus, .btn-default.disabled:active, .btn-default[disabled]:active, fieldset[disabled] .btn-default:active, .btn-default.disabled.active, .btn-default.active[disabled], fieldset[disabled] .btn-default.active {
    background-color: #ccc;
    border-color: #CCC;
	color: #000;
        font-size: 16px;
}

.btn-default.disabled, .btn-default[disabled], fieldset[disabled] .btn-default, .btn-default.disabled:hover, .btn-default[disabled]:hover, fieldset[disabled] .btn-default:hover, .btn-default.disabled:focus, .btn-default[disabled]:focus, fieldset[disabled] .btn-default:focus, .btn-default.disabled.focus, .btn-default.focus[disabled], fieldset[disabled] .btn-default.focus, .btn-default.disabled:active, .btn-default[disabled]:active, fieldset[disabled] .btn-default:active, .btn-default.disabled.active, .btn-default.active[disabled], fieldset[disabled] .btn-default.active {
    background-color: #ccc;
    border-color: #CCC;
	color: #000;
        font-size: 16px;
}

.btn-success {
  color: #fff;
  background-color: #0ac775;
  border-color: #0ac775;
        font-size: 16px;
}

.btn-success:hover {
  color: #fff;
  background-color: #449d44;
  border-color: #419641;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #449d44;
  border-color: #419641;
}

.btn-success:active, .btn-success.active,
.open > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #449d44;
  border-color: #419641;
  background-image: none;
}

.btn-success:active:hover, .btn-success:active:focus, .btn-success:active.focus, .btn-success.active:hover, .btn-success.active:focus, .btn-success.active.focus,
.open > .btn-success.dropdown-toggle:hover,
.open > .btn-success.dropdown-toggle:focus,
.open > .btn-success.dropdown-toggle.focus {
  color: #fff;
  background-color: #398439;
  border-color: #2d672d;
}

.btn-success.disabled:focus, .btn-success.disabled.focus, .btn-success:disabled:focus, .btn-success:disabled.focus {
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.btn-success.disabled:hover, .btn-success:disabled:hover {
  background-color: #5cb85c;
  border-color: #5cb85c;
}
.btn-current {
  color: #fff;
  background-color: #00adef;
  border-color: #00adef;
}

.btn-current:hover {
  color: #fff;
  background-color: #0078a6;
  border-color: #0078a6;
}

.btn-current:focus, .btn-current.focus {
  color: #fff;
  background-color: #0078a6;
  border-color: #0078a6;
}

.btn-current:active, .btn-current.active,
.open > .btn-current.dropdown-toggle {
  color: #fff;
  background-color: #0078a6;
  border-color: #0078a6;
  background-image: none;
}

.btn-current:active:hover, .btn-current:active:focus, .btn-current:active.focus, .btn-current.active:hover, .btn-current.active:focus, .btn-current.active.focus,
.open > .btn-current.dropdown-toggle:hover,
.open > .btn-current.dropdown-toggle:focus,
.open > .btn-current.dropdown-toggle.focus {
  color: #fff;
  background-color: #0078a6;
  border-color: #0078a6;
}

.btn-current.disabled:focus, .btn-current.disabled.focus, .btn-current:disabled:focus, .btn-current:disabled.focus {
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.btn-current.disabled:hover, .btn-current:disabled:hover {
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.home_icon {
    text-align: center;
    font-size: 40px;
    color: #325158;
}

.navbar {
    background-color: #f9fbfc;
    padding-left: 10px;
    padding-right: 0;
    padding-top: 4px;
    padding-bottom: 0;
}

.nav_icon {
    font-size: 1.5em;
    padding-right: 10px;
    padding-top: 15px;
    color: #231f20
}

.nav_text {
    padding-right: 90px;
    padding-top: 15px;
    font-size: 1em;
}

.divider {
  border-bottom: 1px solid #eee;
}

ul.dropdown-menu {
    position: absolute;
    margin-left: 0px;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 100px;
    padding: 0px 0;
    font-size: .9rem;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
}

ul.dropdown-menu-right-attach {
    left: auto;
    right: 0px;
}

ul.dropdown-menu2 {
    position: absolute;
    margin-left: 295px;
    top: 89px;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 280px;
    padding: 15px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #00adef;
    border-radius: 3px;
    background-color: #00adef;
    box-shadow: 0 1px 2px rgba(0,0,0,.12)
}


ul.dropdown-menu3 {
    position: absolute;
    margin-left: 0px;
    top: 140px;
    left: 460px;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 280px;
    padding: 15px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #00adef;
    border-radius: 3px;
    background-color: #00adef;
    box-shadow: 0 1px 2px rgba(0,0,0,.12)
}

ul.dropdown-menu4 {
    position: absolute;
    margin-left: 805px;
    top: 180px;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    width: 280px;
    height: 900px;
    padding: 15px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #00adef;
    border-radius: 3px;
    background-color: #00adef;
    box-shadow: 0 1px 2px rgba(0,0,0,.12)
}

.dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
        margin-left: .3em;
    margin-right: .3em;
    margin-bottom: .1em;
    content: "";
    color: #231f20;
}

.dropdown-toggle2::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: -.3em;
    margin-right: .3em;
    margin-bottom: .5em;
    content: "";
    color: transparent;
}

.dropdown-toggle3::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .3em;
    content: "";
    color: #fff !important;
}

.dropdown-item {
    display: block;
    width: 100%;
    line-height: 1.42857143;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    color: #231f20;
    text-align: inherit;
    white-space: nowrap;
    background-color: #fff;
    border: 0;
    margin-right: -60px;
}

.dropdown-item:focus, .dropdown-item:hover {
    color: #fff;
    text-decoration: none;
    background-color: #00adef;
}

.dropdown-menu > li > a, .dropdown-menu-right > li > a {
    display: block;
    padding: 6px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #231f20;
    white-space: nowrap;
}

.dropdown-menu > li > a:hover, .dropdown-menu-right > li > a:hover {
    background-color: #00adef;
    color: #fff;
}

.footer {
	clear: both;
    bottom: 0;
    width: 100%;
}
.main-footer {
    margin-left: 0;
    padding: 15px;
    height: 50px;
    border-top: 1px solid #ECEFF1;
    color: #869aa0;
    font-size: .8em;
    font-weight: normal;
}

.navlabel {
    position: absolute;
    width: 15px;
    height: 15px;
    padding: 3px;
    margin-top: 12px;
    margin-left: -18px;
    line-height: .9;
    font-size: .9em;
    color: #fff;
    border-radius: 50%;
    background-color: #0ac775;
    text-align: center;
}

/* use this on home page card */

.products-list .product-description {
    display: block;
     overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: .9em;
    color: #546e7a;

}
.product-list-in-box > .item {
  -webkit-box-shadow: none;
  box-shadow: none;
    font-size:1em;
  border-radius: 0;
  border-bottom: 1px solid #f4f4f4;
}
.product-list-in-box > .item:last-of-type {
  border-bottom-width: 0;
}

.products-list .product-img img {
  width: 20px;
  height: 20px;
}

.products-list .product-info {
  margin-left: 30px;
    font-size: 1em;
}
/* NAV TABS */
.nav-tabs {
  border-bottom: 1px solid #ECEFF1;
}



nav > li > a:hover, .nav > li > a:active, .nav > li > a:focus {
    color: #fff;
    background: transparent;
}

/* unselected tab */
.nav-tabs .nav-link {
  display: block;
  padding: 0.5em 1em;
  border-bottom: 1px solid #ECEFF1;
  border-top: 1px solid #ECEFF1;
  border-left: 1px solid #ECEFF1;
  border-right: 1px solid #ECEFF1;
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  background-color: #fff;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    background-color: #00adef;
    color: #fff;
}

.nav-tabs .nav-link.disabled, .nav-tabs .nav-link.disabled:focus, .nav-tabs .nav-link.disabled:hover {
  color: #818a91;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-link.active:focus, .nav-tabs .nav-link.active:hover,
.nav-tabs .nav-item.open .nav-link,
.nav-tabs .nav-item.open .nav-link:focus,
.nav-tabs .nav-item.open .nav-link:hover {
  color: #231f20;
  background-color: #fff;
  border-color: #00adef #ECEFF1 transparent;
}

/* PAGINATION */
.pagination > li > a {
  background: #fafafa;
  color: #666;
  border-radius: 0 !important;
}

.info-box {
  display: block;
  min-height: 50px;
  background: #fff;
  width: 100%;
  border: 1px solid silver;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  border-radius: 2px;
  margin-bottom: 15px;
}
.info-box-icon {
  display: block;
  float: left;
  height: 49px;
  width: 49px;
  text-align: center;
  font-size: 20px;
  line-height: 50px;
    color: white;
}

.info-box-content {
    padding-top: 10px;
    margin-left: 55px;
}

.info-box-text {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
   font-size: 1.2em;
}
.info-box-text {
  text-transform: none;
}

.text-small {
  font-size: .9em;
    line-height: 1.2;
}

.view {
  font-size: .9em;
  line-height: 1.2;
    background-color: #fff;
    padding: 3px;
    margin-top: 10px;
}
.thumbnail {
    margin-bottom: 20px;
    padding: 10px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .12);
    border: 1px solid #ECEFF1;
    min-height: 200px;
    max-height: 200px;
}

.downInfo {
    font-size: 80%;
    background-color: #ECEFF1;
    line-height: 1.2;
    padding: 4px;
    margin-bottom: 6px;
}

.thumbnail_list
{
    padding: 10px;
    margin: 0 auto;
    margin-bottom: 20px;
    width: 96%;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    box-shadow: 0 1px 2px rgba(0,0,0,.12);
    border: 1px solid #ECEFF1;
}

.item.list-group-item
{
    float: none;
    width: 100%;
    background-color: #fff;
    margin-bottom: 10px;
    border: none;
}
.item.list-group-item:nth-of-type(odd):hover,.item.list-group-item:hover
{
    background: #dce9ec;
}

.item.list-group-item .list-group-image
{
    margin-right: 10px;
}
.item.list-group-item .thumbnail
{
    margin-bottom: 0px;
}
.item.list-group-item .caption
{
    padding: 9px 9px 0px 9px;
}
.item.list-group-item:nth-of-type(odd)
{
    background: #fff;
}

.item.list-group-item:before, .item.list-group-item:after
{
    display: table;
    content: " ";
}

.item.list-group-item img
{
    float: left;
}
.item.list-group-item:after
{
    clear: both;
}
.list-group-item-text
{
    margin: 0 0 11px;
    font-size: .9em;
    color: #546e7a;
}

.size {
    /* color: #546e7a; */
    line-height: 1.1;
    font-size: .9em;
    padding-bottom: 10px;
    padding-top: 5px;
    margin: 0 auto;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  border-top: 1px solid #ECEFF1;
}
.table > thead > tr > th {
  border-bottom: 2px solid #ECEFF1;
}

.table thead th {
    vertical-align: middle;
    line-height: 1.1;
}
.table-hover tbody tr:hover {
  background-color: #f9fbfc;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ECEFF1;
     border-bottom: 1px solid #ECEFF1;
}

  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ECEFF1 !important;
      vertical-align: middle;
  }

.table2 th,
.table2 td {
  padding: 0.35rem;
  vertical-align: top;
  border-top: 1px solid #ECEFF1;
    border-bottom: 1px solid #ECEFF1;
    width: 100%;
}

.table3 th,
.table3 td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #ECEFF1;
}

.table3 td > .fa {
    border-radius: 50%;
    text-align: center;    width: 30px;
    height: 30px;
    font-size: 16px;
    line-height: 30px;
}

.table4 th,
.table4 td {
  padding: 0.35rem;
  vertical-align: top;
  border-top: 1px solid #ECEFF1;
  color: #546e7a;
    line-height: 1.15;
    font-size: .9em;
}

.icon_wrapper {
      display:inline-block;
    margin-left: 18px;
}
.timeline > li.time-label > span {
    font-weight: 600;
    padding: 5px;
    width: 100px;
    text-align: center;
    display: inline-block;
    background-color: #0078A6;
    border-radius: 4px;
    font-size: .9em;
    color: #fff;
}
/*
.timeline > li > .fa, .timeline > li > .glyphicon, .timeline > li > .ion {
    width: 30px;
    height: 30px;
    font-size: 16px;
    line-height: 30px;
    background: #78909C;
    position: absolute;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    left: 18px;
    top: 0;
}
*/


.timeline-body {
    color: #546e7a;
    font-size: .9em;
}

.timeline > li > .timeline-item > .timeline-header {
    margin: 0;
    margin: 0;
    color: #231f20;
    border-bottom: 1px solid #f4f4f4;
      border-top: 1px solid #f4f4f4;
    border-radius: 4px;
    padding: 10px;
    font-size: 16px;
    line-height: 1.6;
}

.timeline > li > .timeline-item {
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
     box-shadow: 0 1px 1px rgba(0,0,0,.05);
  border-radius: 3px;
  margin-top: 0px;
  background: #fff;
  color: #546E7A;
  margin-left: 60px;
  margin-right: 15px;
  padding: 0;
  position: relative;
}
.timeline > li > .timeline-item > .time {
  color: #546E7A;
  float: right;
  padding: 10px;
  font-size: 12px;
}

/* Radio Buttons
   ========================================================================== */
[type="radio"]:not(:checked),
[type="radio"]:checked {
  position: absolute;
  left: -9999px;
  opacity: 0;
}

[type="radio"]:not(:checked) + label,
[type="radio"]:checked + label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  display: inline-block;
  height: 5px;
  line-height: 25px;
  font-size: 1rem;
  transition: .28s ease;
  /* webkit (konqueror) browsers */
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

[type="radio"] + label:before,
[type="radio"] + label:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  margin: 4px;
  width: 16px;
  height: 16px;
  z-index: 0;
  transition: .28s ease;
}

/* Unchecked styles */
[type="radio"]:not(:checked) + label:before,
[type="radio"]:not(:checked) + label:after,
[type="radio"]:checked + label:before,
[type="radio"]:checked + label:after,
[type="radio"].with-gap:checked + label:before,
[type="radio"].with-gap:checked + label:after {
  border-radius: 50%;
}

[type="radio"]:not(:checked) + label:before,
[type="radio"]:not(:checked) + label:after {
  border: 1px solid rgba(0, 0, 0, 0.25);
}

[type="radio"]:not(:checked) + label:after {
  -webkit-transform: scale(0);
          transform: scale(0);
}

/* Checked styles */
[type="radio"]:checked + label:before {
  border: 2px solid transparent;
}

[type="radio"]:checked + label:after,
[type="radio"].with-gap:checked + label:before,
[type="radio"].with-gap:checked + label:after {
  border: 1px solid #0ac746;
}

[type="radio"]:checked + label:after,
[type="radio"].with-gap:checked + label:after {
  background-color: #0ac746;
}

[type="radio"]:checked + label:after {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

/* Radio With gap */
[type="radio"].with-gap:checked + label:after {
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
}

/* Focused styles */
[type="radio"].tabbed:focus + label:before {
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Disabled Radio With gap */
[type="radio"].with-gap:disabled:checked + label:before {
  border: 1px solid rgba(0, 0, 0, 0.26);
}

[type="radio"].with-gap:disabled:checked + label:after {
  border: none;
  background-color: rgba(0, 0, 0, 0.26);
}

/* Disabled style */
[type="radio"]:disabled:not(:checked) + label:before,
[type="radio"]:disabled:checked + label:before {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.26);
}

[type="radio"]:disabled + label {
  color: rgba(0, 0, 0, 0.26);
}

[type="radio"]:disabled:not(:checked) + label:before {
  border-color: rgba(0, 0, 0, 0.26);
}

[type="radio"]:disabled:checked + label:after {
  background-color: rgba(0, 0, 0, 0.26);
  border-color: #BDBDBD;
}

/* Checkboxes
   ========================================================================== */
/* CUSTOM CSS CHECKBOXES */
form p {
  margin-bottom: 10px;
  text-align: left;
}

form p:last-child {
  margin-bottom: 0;
}

/* Remove default checkbox */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
  opacity: 0;
}

[type="checkbox"] {
  /* checkbox aspect */
}

[type="checkbox"] + label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  height: 10px;
  line-height:1.2em; /* align checkbox with label here */
  font-size: 1rem;
  -webkit-user-select: none;
  /* webkit (safari, chrome) browsers */
  -moz-user-select: none;
  /* mozilla browsers */
  -khtml-user-select: none;
  /* webkit (konqueror) browsers */
  -ms-user-select: none;
  /* IE10+ */
}

[type="checkbox"] + label:before,
[type="checkbox"]:not(.filled-in) + label:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  z-index: 0;
 border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 1px;
  margin-top: 2px;
  transition: .2s;
}

[type="checkbox"]:not(.filled-in) + label:after {
  border: 0;
  -webkit-transform: scale(0);
          transform: scale(0);
}

[type="checkbox"]:not(:checked):disabled + label:before {
  border: none;
  background-color: rgba(0, 0, 0, 0.26);
}

[type="checkbox"].tabbed:focus + label:after {
  -webkit-transform: scale(1);
          transform: scale(1);
  border: 0;
  border-radius: 50%;
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.1);
}

[type="checkbox"]:checked + label:before {
  top: -4px;
  left: -5px;
  width: 12px;
  height: 22px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid #26a69a;
  border-bottom: 2px solid #26a69a;
  -webkit-transform: rotate(40deg);
          transform: rotate(40deg);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
}

[type="checkbox"]:checked:disabled + label:before {
  border-right: 1px solid rgba(0, 0, 0, 0.26);
  border-bottom: 1px solid rgba(0, 0, 0, 0.26);
}

/* Indeterminate checkbox */
[type="checkbox"]:indeterminate + label:before {
  top: -11px;
  left: -12px;
  width: 10px;
  height: 22px;
  border-top: none;
  border-left: none;
  border-right: 1px solid #26a69a;
  border-bottom: none;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
}

[type="checkbox"]:indeterminate:disabled + label:before {
  border-right: 1px solid rgba(0, 0, 0, 0.26);
  background-color: transparent;
}

[type="checkbox"].filled-in + label:after {
  border-radius: 3px; /* this is the checkbox itself */
}

[type="checkbox"].filled-in + label:before,
[type="checkbox"].filled-in + label:after {
  content: '';
  left: 0;
  position: absolute;
  /* .1s delay is for check animation */
  transition: border .25s, background-color .25s, width .20s .1s, height .20s .1s, top .20s .1s, left .20s .1s;
  z-index: 1;
}

[type="checkbox"].filled-in:not(:checked) + label:before {
  width: 0;
  height: 0;
  border: 2px solid transparent;
  left: 6px;
  top: 10px;
  -webkit-transform: rotateZ(37deg);
  transform: rotateZ(37deg);
  -webkit-transform-origin: 20% 40%;
  transform-origin: 100% 100%;
}

[type="checkbox"].filled-in:not(:checked) + label:after {
  height: 20px;
  width: 20px;
  background-color: transparent;
      border: 1px solid rgba(0, 0, 0, 0.15);
  top: 0px;
  z-index: 0;
}

[type="checkbox"].filled-in:checked + label:before {
  top: 0;
  left: 1px;
  width: 8px;
  height: 13px;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 2px solid #fff;  /* thickness of checkmark here */
  border-bottom: 2px solid #fff;
  -webkit-transform: rotateZ(37deg);
  transform: rotateZ(37deg);
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

[type="checkbox"].filled-in:checked + label:after {
  top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #0ac746;
  background-color: #0ac746;
  z-index: 0;
}

[type="checkbox"].filled-in.tabbed:focus + label:after {
  border-radius: 2px;
  border-color: #5a5a5a;
  background-color: rgba(0, 0, 0, 0.1);
}

[type="checkbox"].filled-in.tabbed:checked:focus + label:after {
  border-radius: 2px;
  background-color: #26a69a;
  border-color: #26a69a;
}

[type="checkbox"].filled-in:disabled:not(:checked) + label:before {
  background-color: transparent;
  border: 2px solid transparent;
}

[type="checkbox"].filled-in:disabled:not(:checked) + label:after {
  border-color: transparent;
  background-color: #BDBDBD;
}

[type="checkbox"].filled-in:disabled:checked + label:before {
  background-color: transparent;
}

[type="checkbox"].filled-in:disabled:checked + label:after {
  background-color: #BDBDBD;
  border-color: #BDBDBD;
}

.closewhite {
    color: #fff;
    text-shadow: 0 1px 0 #fff;
    opacity: .9;
    float: right;
    margin-right: 5px;
}

.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.glyphicon-remove:before {
  content: "\e014";
}

.form-control-feedback {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    display: block;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    pointer-events: none;
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: #ED1C24;
}
.has-error .form-control {
  border-color: #ED1C24;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}
.has-error .form-control:focus {
  border-color: #ED1C24;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483;
}
.has-error .input-group-addon {
  color: #ED1C24;
  background-color: #ED1C24;
  border-color: #ED1C24;
}
.has-error .form-control-feedback {
  color: #ED1C24;
}
.has-feedback label ~ .form-control-feedback {
  top: 1px;
}
.has-feedback label.sr-only ~ .form-control-feedback {
  top: 0;
}
.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373;
}

.pagination {
    display: inline-block;
    padding-left: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius: .25rem;
}

.pagination > li > a {
    background: #fff;
    color: #0078a6;
    border-radius: 0 !important;
}

.page-item.active .page-link, .page-item.active .page-link:focus, .page-item.active .page-link:hover {
  z-index: 2;
  color: #fff;
  cursor: default;
  background-color: #00adef;
  border-color: #00adef;
}

.page-item.disabled .page-link, .page-item.disabled .page-link:focus, .page-item.disabled .page-link:hover {
  color: #818a91;
  pointer-events: none;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
border-radius: 3px;
}

.page-link {
  position: relative;
  float: left;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  color: #0275d8;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}

.page-link:focus, .page-link:hover {
  color: #fff;
  background-color: #0078a6;
  border-color: #0078a6;
}

.page-item:last-child .page-link {
    border-bottom-right-radius: .25rem !important;
    border-top-right-radius: .25rem !important;
}

.page-item:first-child .page-link {
    border-bottom-left-radius: .25rem !important;
    border-top-left-radius: .25rem !important;
}

.table-hover > tbody > tr.select-row:hover > td,
.select-row > td {
    background-color: #0078a6;
    color: #fff;
}

.attribute {
    background-color: #f9fbfc;
    font-size: .9em;
    width: 100%;
}


.fa-stack:hover .fa-file-o {
            color: #00adef;
        }


.fa-stack:hover  .fa.fa-database.nowhite{ color: #0078a6; }
.fa-stack:hover  .fa.fa-file-o.nowhite{ color: #0078a6; }

.fa-stack2 {
    position: absolute;
    display: inline-block;
    width: 2em;
    height: 2em;
    line-height: 2em;
    vertical-align: middle;
    margin-top: 10px;
}

.fa-lge {
    font-size: 1.12em;
    line-height: 2em;
    margin-left: 16px;
}

.fa-stack-1x.blue:hover {color: #00adef}
.fa-stack-1x.darkblue:hover {color: #0078a6}
.fa-stack-1x.green:hover {color: #28B837}
.fa-stack-1x.yellow:hover {color: #FFA301}
.fa-stack-1x.purple:hover {color: #8D95EC}
.fa-stack-1x.black:hover {color: #231f20}

.banner {
    border-radius: 10px;
    border: 2px solid;
}

.banner-warning {
    border-color: #c65b26;
    background-color: #e5b195;
}

.banner-alert {
    border-color: #bf2e1b;
    background-color: #df978d;
}

.banner-good {
    border-color: #498337;
    background-color: #d7e6d1;
}

.banner-general {
    border-color: #0078a6;
    background-color: #cce7f9;
}

.banner-neutral {
    border-color: #3b3b3f;
    background-color: #e7e7e8;
}

div.scroll {
    margin-top: 0px;
    height: 400px;
    overflow-x: hidden;
    overflow-y: scroll;
    margin-bottom: 10px;
    border: 4px solid #fff;
}

fieldset.fsStyle {
    font-weight: normal;
    border: 1px solid #0078a6;
    padding-left: 10px;
    padding-bottom: 5px;
    margin-top: 15px;
    line-height: 1.2;
}
fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

legend.legendStyle {
    font-size: 1em;
    color: #0078a6;
    background-color: rgba(0, 0, 0, 0);
    margin-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

legend {
    width: auto;
    border-bottom: 0px;
}

.form-group {
    margin-bottom: .7em !important;
}